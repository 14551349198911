<template>
  <div class="salesmans"><salesmans-report /></div>
</template>

<script>
import SalesmansReport from "@/components/Reports/Sales/SalesmansReport.vue";

export default {
  name: "Receivables",
  components: { SalesmansReport },
};
</script>