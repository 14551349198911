<template>
  <div class="salesmans-report">
    <v-card class="mb-2">
      <v-card-title> Laporan Salesman </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1"> Net Sales </v-card-title>

      <v-card-text>
        <h2 v-text="salesmansSales"></h2>
      </v-card-text>
    </v-card>

    <v-card v-if="!isSalesman" class="mb-2">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>HPP</td>
                <td v-text="salesmansCost" class="text-right"></td>
              </tr>
              <tr>
                <td>Gross Profit</td>
                <td v-text="salesmansProfit" class="text-right"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="fetchData"
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :items="salesmans"
        :loading="salesmansLoading"
        :options.sync="options"
        :server-items-length="salesmansCount"
        class="elevation-1"
      >
        <template #[`item.actions`]="{ item }">
          <v-btn
            color="light-blue"
            small
            @click="openDetails(item.salesman_code)"
          >
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'

export default {
  components: { DateRangePicker },

  data() {
    let isSalesman = this.getSalesmanStatus()
    let headers = [
      { text: 'Salesman', value: 'salesman_name' },
      { text: 'HPP', value: 'cost' },
      { text: 'Net Sales', value: 'sales' },
      { text: 'Gross Profit', value: 'profit' },
      { text: '', sortable: false, value: 'actions' },
    ]

    if (isSalesman) {
      headers = [
        { text: 'Salesman', value: 'salesman_name' },
        { text: 'Net Sales', value: 'sales' },
        { text: '', sortable: false, value: 'actions' },
      ]
    }

    return {
      dateRange: null,

      search: '',

      options: {},

      headers: headers,

      isSalesman: isSalesman,

      salesmans: [],

      salesmansCount: 0,

      salesmansCost: 0,

      salesmansSales: 0,

      salesmansProfit: 0,

      salesmansLoading: false,
    }
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.fetchData()
      },
    },

    dateRange() {
      this.fetchData()
    },
  },

  methods: {
    fetchData() {
      if (!this.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.salesmans = []
      this.salesmansLoading = true

      this.$http
        .get('/salesmans/report', {
          params: {
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
          },
        })
        .then((result) => {
          this.salesmans = result.data.result

          this.salesmansCount = result.data.count

          this.salesmansCost = result.data.cost
          this.salesmansSales = result.data.sales
          this.salesmansProfit = result.data.profit

          this.salesmansLoading = false
        })
    },

    openDetails(salesmanCode) {
      this.$router.push(
        '/sales/salesmans/' +
          salesmanCode +
          '/' +
          this.date2sql(this.dateRange[0]) +
          '/' +
          this.date2sql(this.dateRange[1])
      )
    },
  },
}
</script>
